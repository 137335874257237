$slick-default__caption-bg: rgba($cl_Black, 0.55) !default;
$slick-default__caption-bg_xs: $text-color !default;
$slick-default__caption-color: $cl_White !default;

$slick-default__title-color: $slick-default__caption-color !default;
$slick-default__text-color: $slick-default__title-color !default;

$slick-default__arrow-bg: $body-bg !default;
$slick-default__arrow-color: $text-color !default;

$slick-default__arrow-bg_hover: $brand-primary !default;
$slick-default__arrow-color_hover: $cl_White !default;

.slick-default {
    &-caption {
        background: $slick-default__caption-bg;
        @media (max-width: $screen-xs-max) {
            background-color: $slick-default__caption-bg_xs;
        }
        &__title {
            color: $slick-default__title-color;
        }
        &__text {
            color: $slick-default__text-color;
        }
    }
    .slick-arrow {
        // example: @include slick-arrows(28px, 78px, 0, 0px, false);
        i {
            background-color: $slick-default__arrow-bg;
            color: $slick-default__arrow-color;
        }
        &:hover {
            i {
                background-color: $slick-default__arrow-bg_hover;
                color: $slick-default__arrow-color_hover;
            }
        }
    }
}
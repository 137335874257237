/*

    $width:             width of arrow
    $height:            height of arrow
    $arrows-radius:     border-radius for arrow
    $arrows-indent:     space between arrow and edge of slider container;   Use 'false' value to prevent rendering this property
    $text-indent:       indent for icon inside the arrow;                   Use 'false' value to prevent rendering this property

    $xs-padding:        internal variable, how much space add to the arrows on 'sm-max' breakpoint

    Usage:              @include slick-arrows(30px, 80px, 0, 5px, 1px);

*/
@mixin slick-arrows ($width: 38px, $height: 38px, $arrows-radius: 0, $arrows-indent: 0px, $text-indent: 0px) {

    $xs-padding: 6px;
    $xs-arrow-size: 30px;

    position: absolute;
    top: 50%;
    z-index: 1;

    padding: $xs-padding;
    @if unit($xs-arrow-size) == unit($xs-padding) {
        margin-top: -$xs-padding - $xs-arrow-size * 0.5;
    } @else {
        margin-top: calc(-#{$xs-padding} - #{$xs-arrow-size} / 2);
    }

    border: 0;
    background: transparent;
    cursor: pointer;

    line-height: 0;
    color: transparent;

    &:focus {
        outline: none;
    }

    @media (min-width: $screen-sm-min) {
        padding: 0;
        margin-top: -$height * 0.5;
    }

    i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $xs-arrow-size;
        height: $xs-arrow-size;
        border-radius: $arrows-radius;
        @media (min-width: $screen-sm-min) {
            width: $width;
            height: $height;
        }
    }

    &.slick-prev {
        @if $text-indent != false {
            text-indent: -1px;
            @media (min-width: $screen-sm-min) {
                text-indent: -$text-indent;
            }
        }
        @if $arrows-indent != false {
            @if unit($arrows-indent) == unit($xs-padding) {
                left: $arrows-indent - $xs-padding;
                @media (min-width: $screen-sm-min) {
                    left: $arrows-indent;
                }
            } @else {
                left: calc(#{$arrows-indent} - #{$xs-padding});
                @media (min-width: $screen-sm-min) {
                    left: $arrows-indent;
                }
            }
        }
    }

    &.slick-next {
        @if $text-indent != false {
            text-indent: 1px;
            @media (min-width: $screen-sm-min) {
                text-indent: $text-indent;
            }
        }
        @if $arrows-indent != false {
            @if unit($arrows-indent) == unit($xs-padding) {
                right: $arrows-indent - $xs-padding;
                @media (min-width: $screen-sm-min) {
                    right: $arrows-indent;
                }
            } @else {
                right: calc(#{$arrows-indent} - #{$xs-padding});
                @media (min-width: $screen-sm-min) {
                    right: $arrows-indent;
                }
            }
        }
    }

}

@mixin slick-circle-arrows ($size: 38px, $arrows-indent: 0px, $text-indent: 0px) {
    @include slick-arrows($size, $size, 50%, $arrows-indent, $text-indent);
}

@mixin slick-square-arrows ($size: 38px, $arrows-indent: 0px, $text-indent: 0px) {
    @include slick-arrows($size, $size, 0, $arrows-indent, $text-indent);
}

/*

    $width:             width of arrow
    $height:            height of arrow
    $arrows-radius:     border-radius for arrow
    $arrows-indent:     space between arrow and edge of slider container;   Use 'false' value to prevent rendering this property
    $text-indent:       indent for icon inside the arrow;                   Use 'false' value to prevent rendering this property

    Usage:              @include slick-arrows-theme ($icon-color, $text-color, $body-bg, $cl_White, $brand-primary, $brand-primary);
                        @include slick-arrows-theme ();

*/
@mixin slick-arrows-theme($icon-color: $text-color, $arrow-bg: $body-bg, $arrow-border-color: $body-bg, $icon-color-hover: $cl_White, $arrow-bg-hover: $brand-primary, $arrow-border-color-hover: $brand-primary){
    i {
        color: $icon-color;
        background-color: $arrow-bg;
        border: 1px solid $arrow-border-color;
    }
    &:hover {
        i {
            color: $icon-color-hover;
            background-color: $arrow-bg-hover;
            border: 1px solid $arrow-border-color-hover;
        }
    }
}


/*

    $width:             width of dot
    $height:            height of dot
    $dots-radius:       border-radius for dot
    $dots-space:        space between dots

    Usage:              @include slick-dots(15px, 15px, 50%);

*/
@mixin slick-dots ($width: 15px, $height: 15px, $dots-radius: 0, $dots-space: 20px) {

    // .slick-dots - this is <ul> element
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        position: relative;
        &:not(:first-child) {
            margin-left: $dots-space;
        }
    }

    button {
        display: block;
        width: $width;
        height: $height;
        border: 0;
        border-radius: $dots-radius;
        padding: 0;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
}

@mixin slick-circle-dots ($size: 15px, $dots-space: 20px) {
    @include slick-dots($size, $size, 50%, $dots-space);
    text-indent: -99px;
    font-size: 0;
    overflow: hidden;
}

@mixin slick-square-dots ($size: 15px, $dots-space: 20px) {
    @include slick-dots($size, $size, 0, $dots-space);
}

﻿.slick-dots {
    @include slick-circle-dots(1.1rem, 0.5rem);

    .uc1120 & {
        position: absolute;
        bottom: 1.1rem;
        left: 0;
        right: 0;
    }

    .uc1122 & {
        margin-top: 2.4rem;
    }

    button {
        background-color: rgba($cl_Silver, .5);
        border: .1rem solid rgba($brand-primary, .5);
    }

    .slick-active button,
    button:hover{
        background-color: $brand-primary;
        border: .1rem solid $cl_Silver;
    }
}